@import "./tailwind.css";

@import "./1_settings/sizes.css";

@import "./2_tools/mixins.css";
@import "./2_tools/magnific.css";

@import "./3_generic/shared.css";

@import "./4_elements/typography.css";
@import "./4_elements/generic.css";
@import "./4_elements/form.css";
@import "./4_elements/table.css";

@import "./5_atoms/wrapper.css";
@import "./5_atoms/form.css";
@import "./5_atoms/btn.css";

@import "./6_components/site/header.css";
@import "./6_components/site/footer.css";
@import "./6_components/breadcrumb.css";
@import "./6_components/hero.css";
@import "./6_components/secondary-hero.css";
@import "./6_components/faqs.css";
@import "./6_components/colour-cards.css";
@import "./6_components/page-cards.css";
@import "./6_components/story-cards.css";
@import "./6_components/download-cards.css";
@import "./6_components/people-cards.css";
@import "./6_components/detail-card.css";
@import "./6_components/event-cards.css";
@import "./6_components/text-image.css";
@import "./6_components/secondary-text-image.css";
@import "./6_components/pagination.css";
@import "./6_components/form-designer.css";
@import "./6_components/two-col-text.css";
@import "./6_components/video-block.css";
@import "./6_components/inline-quote.css";
@import "./6_components/quote-banner.css";
@import "./6_components/donation-banner.css";
@import "./6_components/logo-block.css";
@import "./6_components/gallery.css";
@import "./6_components/title-block.css";
@import "./6_components/share-this-page.css";



/* @import "./7_overrides/override.css"; */
