*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 375px) {
  .container {
    max-width: 375px;
  }
}

@media (width >= 561px) {
  .container {
    max-width: 561px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 769px) {
  .container {
    max-width: 769px;
  }
}

@media (width >= 961px) {
  .container {
    max-width: 961px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1025px) {
  .container {
    max-width: 1025px;
  }
}

@media (width >= 1261px) {
  .container {
    max-width: 1261px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-px {
  bottom: 1px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-10 {
  left: 2.5rem;
}

.left-11 {
  left: 2.75rem;
}

.left-12 {
  left: 3rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-20 {
  left: 5rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-32 {
  left: 8rem;
}

.left-36 {
  left: 9rem;
}

.left-4 {
  left: 1rem;
}

.left-40 {
  left: 10rem;
}

.left-44 {
  left: 11rem;
}

.left-48 {
  left: 12rem;
}

.left-5 {
  left: 1.25rem;
}

.left-52 {
  left: 13rem;
}

.left-56 {
  left: 14rem;
}

.left-6 {
  left: 1.5rem;
}

.left-60 {
  left: 15rem;
}

.left-64 {
  left: 16rem;
}

.left-7 {
  left: 1.75rem;
}

.left-72 {
  left: 18rem;
}

.left-8 {
  left: 2rem;
}

.left-80 {
  left: 20rem;
}

.left-9 {
  left: 2.25rem;
}

.left-96 {
  left: 24rem;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-10 {
  right: 2.5rem;
}

.right-11 {
  right: 2.75rem;
}

.right-12 {
  right: 3rem;
}

.right-14 {
  right: 3.5rem;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-32 {
  right: 8rem;
}

.right-36 {
  right: 9rem;
}

.right-4 {
  right: 1rem;
}

.right-40 {
  right: 10rem;
}

.right-44 {
  right: 11rem;
}

.right-48 {
  right: 12rem;
}

.right-5 {
  right: 1.25rem;
}

.right-52 {
  right: 13rem;
}

.right-56 {
  right: 14rem;
}

.right-6 {
  right: 1.5rem;
}

.right-60 {
  right: 15rem;
}

.right-64 {
  right: 16rem;
}

.right-7 {
  right: 1.75rem;
}

.right-72 {
  right: 18rem;
}

.right-8 {
  right: 2rem;
}

.right-80 {
  right: 20rem;
}

.right-9 {
  right: 2.25rem;
}

.right-96 {
  right: 24rem;
}

.right-px {
  right: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-7 {
  top: 1.75rem;
}

.top-72 {
  top: 18rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-9 {
  top: 2.25rem;
}

.top-96 {
  top: 24rem;
}

.top-px {
  top: 1px;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/4 {
  height: 50%;
}

.h-2\/5 {
  height: 40%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-3\/4 {
  height: 75%;
}

.h-3\/5 {
  height: 60%;
}

.h-3\/6 {
  height: 50%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/5 {
  height: 80%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/6 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.w-wrapper {
  width: 1300px;
}

.w-wrapper-thin {
  width: 1200px;
}

.w-wrapper-very-thin {
  width: 1100px;
}

.w-wrapper-xs {
  width: 816px;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(19 20 22 / var(--tw-border-opacity));
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

.border-blue-950 {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.border-cyan-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-footer-grey {
  --tw-border-opacity: 1;
  border-color: rgb(125 129 145 / var(--tw-border-opacity));
}

.border-fuchsia-100 {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.border-fuchsia-200 {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.border-fuchsia-300 {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.border-fuchsia-400 {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.border-fuchsia-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.border-fuchsia-700 {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.border-fuchsia-800 {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.border-fuchsia-900 {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.border-fuchsia-950 {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

.border-green-950 {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgb(196 196 205 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.border-indigo-950 {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.border-inherit {
  border-color: inherit;
}

.border-light-grey {
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

.border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 247 237 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity));
}

.border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18 / var(--tw-border-opacity));
}

.border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

.border-orange-950 {
  --tw-border-opacity: 1;
  border-color: rgb(67 20 7 / var(--tw-border-opacity));
}

.border-paper-white {
  --tw-border-opacity: 1;
  border-color: rgb(249 249 249 / var(--tw-border-opacity));
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-pink-950 {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-purple-950 {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.border-rose-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.border-rose-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.border-rose-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.border-rose-800 {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.border-rose-900 {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.border-rose-950 {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.border-search-grey {
  --tw-border-opacity: 1;
  border-color: rgb(232 234 241 / var(--tw-border-opacity));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.border-slate-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.border-stone-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.border-stone-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.border-stone-700 {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.border-stone-900 {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.border-stone-950 {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.border-table-border {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity));
}

.border-table-row {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

.border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

.border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

.border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

.border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

.border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.border-teal-950 {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.border-violet-200 {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.border-violet-50 {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.border-violet-700 {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.border-violet-800 {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.border-violet-950 {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(248 232 77 / var(--tw-border-opacity));
}

.border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.border-zinc-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.border-zinc-700 {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.border-zinc-800 {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.border-zinc-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.border-zinc-950 {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-footer-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(125 129 145 / var(--tw-bg-opacity));
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.bg-green-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(196 196 205 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

.bg-orange-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

.bg-paper-white {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.bg-search-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(232 234 241 / var(--tw-bg-opacity));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-table-border {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.bg-table-row {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.bg-teal-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-4 {
  padding: 1rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-72 {
  padding: 18rem;
}

.p-8 {
  padding: 2rem;
}

.p-80 {
  padding: 20rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-2xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 2.4rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-base {
  font-size: 20px;
}

.text-l {
  font-size: 1.2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-s {
  font-size: .8rem;
}

.text-sm {
  font-size: .9rem;
}

.text-xl {
  font-size: 1.6rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

.leading-normal {
  line-height: 1.5;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-footer-grey {
  --tw-text-opacity: 1;
  color: rgb(125 129 145 / var(--tw-text-opacity));
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-green-950 {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(196 196 205 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-light-grey {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.text-orange-950 {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

.text-paper-white {
  --tw-text-opacity: 1;
  color: rgb(249 249 249 / var(--tw-text-opacity));
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.text-search-grey {
  --tw-text-opacity: 1;
  color: rgb(232 234 241 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.text-table-border {
  --tw-text-opacity: 1;
  color: rgb(224 224 224 / var(--tw-text-opacity));
}

.text-table-row {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.text-teal-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.text-teal-950 {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
}

.text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  --wrapper-width: 1300px;
  --wide-wrapper-width: 1500px;
  --small-wrapper-width: 1024px;
}

.mfp-bg {
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  text-align: center;
  z-index: 1044;
  width: auto;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  text-align: center;
  opacity: .65;
  color: #fff;
  width: 44px;
  height: 44px;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  -webkit-tap-highlight-color: transparent;
  width: 90px;
  height: 110px;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  border: inset #0000;
  width: 0;
  height: 0;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  background: #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  z-index: -1;
  background: #444;
  width: auto;
  height: auto;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  cursor: auto;
  width: 100%;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (width <= 800px) and (orientation: landscape), screen and (height <= 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    text-align: center;
    background: #0009;
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (width <= 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-container {
  padding: 1rem;
}

.mfp-content {
  background-color: #0000 !important;
  max-width: 60rem !important;
}

.mfp-content iframe {
  width: 95%;
  height: 95%;
  top: 2.5%;
  left: 2.5%;
}

.mfp-content img {
  width: 100%;
  height: auto;
  padding-top: 0;
}

.mfp-content .mfp-figure:after {
  display: none;
}

.mfp-content .mfp-close {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: 1;
  background-image: url("nav-closer.69760925.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 0;
  top: -1rem;
  right: -1rem;
}

.mfp-content .mfp-video-caption {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: .625rem 1rem;
  font-size: 20px;
  top: 100%;
  bottom: auto;
}

@media (width >= 640px) {
  .mfp-content .mfp-video-caption {
    font-size: .8rem;
  }
}

.content-modal {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.content-modal iframe {
  width: 100%;
  height: 50vw;
  max-height: 16.667rem;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, blockquote, p, address, hr, fieldset, figure, pre {
  margin: 0;
  padding-bottom: 1rem;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, ul:last-child, ol:last-child, dl:last-child, blockquote:last-child, p:last-child, address:last-child, hr:last-child, fieldset:last-child, figure:last-child, pre:last-child {
  padding-bottom: 0;
}

.semi-bold {
  font-weight: 600;
}

ul, ol, dd {
  padding-left: 1rem;
}

h5, h6 {
  font-size: 20px;
  font-weight: 700;
}

html {
  font-size: 20px;
}

body {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.5;
}

input, select, textarea, button {
  font: inherit;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 20px;
}

h5 {
  margin-bottom: .5rem;
  font-size: 20px;
}

h6 {
  margin-bottom: .5rem;
  font-size: 20px;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, p, blockquote, address {
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration-line: underline;
}

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6, ol + h1, ol + h2, ol + h3, ol + h4, ol + h5, ol + h6, ul + h1, ul + h2, ul + h3, ul + h4, ul + h5, ul + h6 {
  padding-top: 1rem;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

html {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
}

img {
  max-width: 100%;
}

iframe {
  display: block;
}

hr {
  --tw-bg-opacity: 1;
  background-color: rgb(196 196 205 / var(--tw-bg-opacity));
  border-width: 0;
  height: 1px;
  margin-bottom: 1rem;
  padding: 0;
}

.text-block {
  margin-bottom: 5rem;
}

input::placeholder, select::placeholder, textarea::placeholder {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  opacity: 1;
}

input, select, textarea {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(196 196 205 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  width: 100%;
  padding: .667rem .557rem;
  display: block;
}

input[disabled], select[disabled], textarea[disabled] {
  cursor: default;
  opacity: .65;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
  width: 1rem !important;
  height: 1rem !important;
}

select {
  padding: .5rem;
}

textarea {
  resize: vertical;
  height: auto;
  min-height: 8rem;
}

label {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  padding-bottom: .5rem;
  font-weight: 700;
  display: block;
  position: relative;
}

label:last-child {
  padding-bottom: 0;
}

label span {
  vertical-align: middle;
  display: inline-block;
}

fieldset {
  border-width: 0;
  margin-bottom: 1rem;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%;
  padding: 0;
}

table th, table td {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  text-align: left;
  padding: .5rem 1rem;
}

table th {
  font-weight: 700;
}

table tbody tr:nth-child(odd) td {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

table .centered {
  text-align: center;
}

table .right {
  text-align: right;
}

.wrapper {
  box-sizing: content-box;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
}

.wrapper > .wrapper {
  padding-left: 0;
  padding-right: 0;
}

.wrapper--thin {
  max-width: 1200px;
}

.wrapper--very-thin {
  max-width: 1100px;
}

.wrapper--xs {
  max-width: 816px;
}

.wrapper:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

input, select, textarea {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(196 196 205 / var(--tw-border-opacity));
  border-radius: .25rem;
}

.field {
  padding-bottom: 1rem;
}

.field:last-child {
  padding-bottom: 0;
}

.field > ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.field > ul label {
  cursor: pointer;
  font-weight: 400;
}

.field > ul li {
  list-style: none;
}

.field.field--checkboxinput label {
  cursor: pointer;
  font-weight: 400;
}

.field span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.field:after {
  content: "";
  clear: both;
  visibility: hidden;
  display: block;
}

.file-input {
  padding: 0;
  position: relative;
}

.file-input input {
  visibility: hidden;
  max-width: none;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.file-input label {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-weight: 600;
  display: block;
}

.file-input label:hover {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
}

.errorlist {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
  margin-top: 0;
  margin-bottom: 0;
  padding: .5rem 0;
}

.errorlist li {
  list-style: none;
}

.btn {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(248 232 77 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  text-align: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  border-radius: 9999px;
  min-width: 6.95rem;
  padding: .375rem 1.776rem;
  font-size: .9rem;
  font-weight: 600;
  text-decoration-line: none;
  display: inline-block;
  position: relative;
}

.btn:hover {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(248 232 77 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
  background-color: #0000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn--secondary {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(19 20 22 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn--secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(19 20 22 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  background-color: #0000;
}

.site-header .wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 961px) {
  .site-header .wrapper {
    flex-wrap: nowrap;
  }
}

.site-header .site-header__logo {
  cursor: pointer;
  flex: 0 0 50%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (width >= 961px) {
  .site-header .site-header__logo {
    flex: none;
    padding-top: 2.15rem;
    padding-bottom: 2.65rem;
  }
}

.site-header .site-header__mobile-nav {
  flex: 0 0 50%;
  display: flex;
}

@media (width >= 961px) {
  .site-header .site-header__mobile-nav {
    display: none;
  }
}

.site-header .site-header__mobile-nav {
  justify-content: right;
}

.site-header.open .site-header__menu {
  display: block;
}

.site-header .site-header__menu {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
  flex: 0 0 100%;
  display: none;
  right: 0;
}

@media (width >= 961px) {
  .site-header .site-header__menu {
    background-color: #0000;
    flex: auto;
    display: block;
    position: static;
  }
}

@media (width >= 1261px) {
  .site-header .site-header__menu {
    position: absolute;
  }
}

.site-header .site-header__menu ul {
  text-align: center;
  flex: 0 0 100%;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0;
  list-style-type: none;
  display: block;
}

@media (width >= 961px) {
  .site-header .site-header__menu ul {
    flex: auto;
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
  }
}

.site-header .site-header__menu ul li > a:after {
  content: "";
  background-color: #0000;
  width: 0;
  height: .15rem;
  margin: auto;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  top: .25rem;
}

.site-header .site-header__menu ul .btn:after {
  height: auto;
}

.site-header .site-header__menu ul li > a:hover:after {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  width: 100%;
}

.site-header .site-header__menu ul li {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-bottom: .75rem;
}

@media (width >= 961px) {
  .site-header .site-header__menu ul li {
    padding-bottom: 0;
    padding-left: .875rem;
    padding-right: .875rem;
  }
}

.site-header .site-header__menu ul li a {
  font-size: .9rem;
  font-weight: 600;
  text-decoration-line: none;
}

.site-header .site-header__menu ul li .btn {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.site-header .site-header__menu ul li .btn:hover {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
}

.site-header .site-header__menu ul li .btn:hover:after {
  background-color: #0000;
  width: 100%;
}

.home-header {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
}

.home-header + .hero {
  margin-top: 0;
}

@media (width >= 961px) {
  .home-header + .hero {
    margin-top: -10rem;
  }
}

.home-header .site-header {
  z-index: 10;
  background: linear-gradient(0deg, #0000, #000);
  position: relative;
}

@media screen and (width <= 960px) {
  .home-header .site-header {
    background: none;
  }
}

.site-footer {
  padding-top: 2rem;
}

@media (width >= 961px) {
  .site-footer {
    padding-top: 4rem;
  }
}

.site-footer p, .site-footer a, .site-footer li, .site-footer span {
  font-size: .8rem;
}

.site-footer h3, .site-footer h4, .site-footer p, .site-footer li, .site-footer span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.site-footer form fieldset {
  padding: 0;
}

.site-footer form fieldset .field-line-2 {
  display: flex;
}

.site-footer form fieldset .field-line-2 .email {
  margin-right: .5rem;
}

.site-footer form fieldset .email {
  width: 100%;
}

@media (width >= 961px) {
  .site-footer form fieldset .email {
    width: 15.5rem;
  }
}

.site-footer form fieldset .email p {
  padding: 0;
}

.site-footer form .btn {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  max-height: 1.8rem;
  padding: .4rem 0;
  font-size: .8rem;
  display: flex;
}

@media (width >= 769px) {
  .site-footer form .btn {
    padding-left: 1.944rem;
    padding-right: 1.944rem;
  }
}

.site-footer form .btn:hover {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
}

.site-footer .social {
  text-align: left;
  margin-bottom: 1rem;
  list-style-type: none;
  display: block;
}

@media (width >= 961px) {
  .site-footer .social {
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
  }
}

.site-footer .social li {
  vertical-align: middle;
  margin-top: 1rem;
  margin-right: .1rem;
  display: inline-block;
}

@media (width >= 961px) {
  .site-footer .social li {
    margin-top: 0;
    margin-right: 0;
  }
}

.site-footer .social li img {
  width: 1.5rem;
}

.site-footer .social a, .site-footer .social a svg {
  display: block;
}

.site-footer .social a svg path {
  transition-property: all;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.site-footer__top {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  flex-flow: wrap;
  justify-content: center;
  padding-bottom: 0;
  display: flex;
}

@media (width >= 961px) {
  .site-footer__top {
    justify-content: space-between;
    padding-bottom: 2rem;
  }
}

.site-footer__top .footer-form {
  flex: 0 0 100%;
}

@media (width >= 961px) {
  .site-footer__top .footer-form {
    flex: 0 0 calc(40% - 1rem);
  }
}

.site-footer__top .footer-form {
  margin-right: 0;
}

@media (width >= 961px) {
  .site-footer__top .footer-form {
    margin-right: 4rem;
  }
}

.site-footer__top .footer-form img {
  margin-bottom: 2rem;
}

.site-footer__top .footer-form h3 {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  margin: 0 0 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}

@media (width >= 961px) {
  .site-footer__top .footer-form h3 {
    border-width: 0;
    margin-bottom: 0;
  }
}

.site-footer__top .footer-form input {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(125 129 145 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(232 234 241 / var(--tw-bg-opacity));
  max-height: 1.8rem;
}

.site-footer__top .footer-form input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .7rem;
}

.site-footer__top .footer-col {
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 961px) {
  .site-footer__top .footer-col {
    flex: 0 0 25%;
    padding: 0 1.8rem;
  }
}

.site-footer__top .footer-col h4 {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.site-footer__top .footer-col:last-child {
  padding-right: 0;
}

.site-footer__top ul {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

@media (width >= 961px) {
  .site-footer__top ul {
    flex: 0 0 100%;
  }
}

.site-footer__top ul li {
  padding-right: .833rem;
  font-size: .9rem;
  line-height: 2;
}

.site-footer__top ul li:last-child {
  padding-right: 0;
}

.site-footer__top ul li a {
  text-decoration-line: none;
}

.site-footer__top ul li a:hover {
  text-decoration-line: underline;
}

.site-footer__lower {
  padding: 3rem 0;
}

.site-footer__lower p, .site-footer__lower li, .site-footer__lower a, .site-footer__lower span {
  color: #fff9;
  font-weight: 600;
}

.site-footer__lower span {
  font-weight: 400;
}

.site-footer__lower ul {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  list-style-type: none;
  display: block;
}

@media (width >= 961px) {
  .site-footer__lower ul {
    margin-bottom: 2rem;
    display: flex;
  }
}

.site-footer__lower ul li {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.333rem;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 961px) {
  .site-footer__lower ul li {
    padding-left: .75rem;
    padding-right: .75rem;
    display: inline-block;
  }
}

.site-footer__lower ul li:first-child {
  padding-left: 0;
}

.site-footer__lower__legal {
  flex-wrap: wrap;
  line-height: 1;
  display: flex;
}

@media (width >= 1025px) {
  .site-footer__lower__legal {
    justify-content: space-between;
    align-items: baseline;
  }
}

.site-footer__lower__legal p {
  width: 100%;
  padding: 0 0 1.333rem;
  line-height: 1.5rem;
}

@media (width >= 961px) {
  .site-footer__lower__legal p {
    width: 70%;
  }
}

.site-footer__lower__legal > ul {
  padding: 0;
  list-style-type: none;
}

.site-footer__lower__legal > ul li {
  font-size: .8rem;
  display: inline-block;
}

.site-footer__lower__legal > ul li:not(:last-child) {
  padding-right: .208rem;
}

.site-footer__lower__legal > ul li a {
  text-decoration-line: none;
  display: inline-block;
}

.site-footer__lower__legal > ul li a:hover {
  text-decoration-line: underline;
}

.site-footer__lower__legal > a {
  align-items: baseline;
  text-decoration-line: none;
  display: flex;
}

.site-footer__lower__legal > a span {
  margin-right: .5rem;
}

.giant-logo {
  align-items: center;
  display: flex;
}

.giant-logo span {
  margin-right: 1rem;
}

.breadcrumbs {
  --tw-bg-opacity: 1;
  background-color: rgb(80 80 80 / var(--tw-bg-opacity));
  width: 100%;
  display: block;
}

.breadcrumbs + .secondary-hero {
  margin-top: -1rem;
}

.breadcrumbs__container ul {
  flex-wrap: wrap;
  align-items: center;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.breadcrumbs__container ul li {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex: 0 auto;
  align-items: center;
  font-size: .8rem;
  line-height: 1.25rem;
  display: none;
}

@media (width >= 561px) {
  .breadcrumbs__container ul li {
    display: flex;
  }
}

.breadcrumbs__container ul li a {
  display: flex;
}

.breadcrumbs__container ul li:after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("chevron-right.8d46ce5d.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  top: 50%;
  right: 0;
}

.breadcrumbs__container ul li:last-child {
  display: block;
}

.breadcrumbs__container ul li:last-child:after {
  display: none;
}

.breadcrumbs__container ul li:last-child:before {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("chevron-right.8d46ce5d.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  top: 50%;
  right: 0;
}

@media (width >= 561px) {
  .breadcrumbs__container ul li:last-child:before {
    display: none;
  }
}

.breadcrumbs__container ul li:nth-last-child(2) {
  padding-right: 0;
  display: flex;
}

.breadcrumbs__container ul li:nth-last-child(2) img {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 561px) {
  .breadcrumbs__container ul li:nth-last-child(2) img {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.breadcrumbs__container ul li:nth-last-child(2) span {
  display: none;
}

@media (width >= 561px) {
  .breadcrumbs__container ul li:nth-last-child(2) span {
    display: block;
  }
}

.breadcrumbs__container ul li a {
  text-decoration-line: none;
}

.breadcrumbs__container ul li a:hover {
  text-decoration-line: underline;
}

.breadcrumbs__container ul li img {
  margin-left: .5rem;
  margin-right: .5rem;
}

.breadcrumbs__container ul .active {
  font-weight: 700;
}

.hero {
  z-index: 2;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20rem;
  margin-bottom: 2.5rem;
  display: block;
  position: relative;
}

@media (width >= 769px) {
  .hero {
    margin-bottom: 5rem;
    display: flex;
  }
}

@media (width >= 961px) {
  .hero {
    min-height: 40rem;
  }
}

.hero .wrapper {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 1261px) {
  .hero .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.hero .wrapper .hero__text {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.111rem 0;
  position: relative;
}

@media (width >= 769px) {
  .hero .wrapper .hero__text {
    text-align: left;
    position: static;
  }
}

@media (width >= 961px) {
  .hero .wrapper .hero__text {
    max-width: 33.4rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.hero .wrapper .hero__text h1 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  padding-right: 0;
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.6rem;
}

@media (width >= 769px) {
  .hero .wrapper .hero__text h1 {
    padding-right: 2rem;
    font-size: 3.2rem;
  }
}

.hero .wrapper .hero__text h1 {
  text-shadow: 0 4px 7px #0000001a;
}

.hero .wrapper .hero__text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 20px;
  font-weight: 500;
}

@media (width >= 769px) {
  .hero .wrapper .hero__text p {
    font-size: 1.2rem;
  }
}

.hero .wrapper .hero__text p {
  text-shadow: 0 4px 7px #0000001a;
}

.hero .hero__overlay {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-from-position: 100%;
  --tw-gradient-to: #131416 var(--tw-gradient-to-position);
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.secondary-hero {
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  position: relative;
}

.secondary-hero .wrapper {
  width: 100%;
  margin: 0;
}

@media (width >= 961px) {
  .secondary-hero .wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.secondary-hero__overlay {
  object-fit: cover;
  background-image: url("overlay-hero.2c0667c8.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.secondary-hero__text {
  text-align: center;
  width: 100%;
  padding: 1.111rem 0;
  position: relative;
}

@media (width >= 769px) {
  .secondary-hero__text {
    text-align: left;
    width: 45%;
    position: static;
  }
}

.secondary-hero__text h1 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-size: 2.4rem;
}

@media (width >= 769px) {
  .secondary-hero__text h1 {
    padding-bottom: 1.5rem;
  }
}

.secondary-hero__text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .8rem;
}

@media (width >= 769px) {
  .secondary-hero__text p {
    font-size: .8rem;
  }
}

.faqs {
  padding-bottom: 2rem;
}

@media (width >= 561px) {
  .faqs {
    padding-bottom: 5rem;
  }
}

.faqs ul {
  padding: 0;
  list-style-type: none;
}

.faqs .opener {
  cursor: pointer;
  text-align: left;
  background-image: none;
  border-width: 0;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  font-size: .9rem;
  font-weight: 700;
  display: flex;
}

.faqs .opener span {
  width: calc(100% - 1.583rem);
}

.faqs .opener img {
  width: auto;
  height: .25rem;
}

.faqs .closed > .content {
  display: none;
}

.faqs .closed .opener img {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.faqs__questions {
  max-width: 36rem;
}

.faqs__questions > li {
  padding-bottom: .222rem;
}

.faqs__questions > li:first-child .opener {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.faqs__questions > li:last-child {
  padding: 0;
}

.faqs__questions > li:last-child .content {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.faqs__questions > li > .opener {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  padding: .75rem 1.6rem .708rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.faqs__questions > li > .content {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: .4rem 1.6rem 2rem;
}

@media (width >= 769px) {
  .faqs__questions > li > .content {
    padding-bottom: .4rem;
  }
}

.faqs__questions > li > .content p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  padding-bottom: 0;
  font-size: .9rem;
}

.faqs__questions .closed:last-child .opener {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.faqs__child-questions {
  margin-bottom: 0;
}

.faqs__child-questions p, .faqs__child-questions li, .faqs__child-questions span {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .9rem;
}

.faqs__child-questions > li > .content ul {
  padding: 0 0 1rem 1rem;
  list-style-type: disc;
}

.faqs__child-questions > li .opener {
  padding: .5rem 0;
}

.faqs__child-questions > li .content {
  padding: .25rem;
}

@media (width >= 769px) {
  .faqs__child-questions > li .content {
    padding: 1rem 0 0;
  }
}

.colour-cards {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  margin-bottom: 5rem;
  padding-bottom: 5rem;
}

.colour-cards .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.colour-cards .wrapper .colour-cards__card {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  border-radius: 1rem;
  flex: 0 0 100%;
  margin-bottom: 1.33rem;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 1.6rem;
}

@media (width >= 769px) {
  .colour-cards .wrapper .colour-cards__card {
    flex: 0 0 calc(50% - 1.33rem);
    margin-left: .665rem;
    margin-right: .665rem;
  }
}

@media (width >= 961px) {
  .colour-cards .wrapper .colour-cards__card {
    flex: 0 0 calc(33.333% - 1.33rem);
  }
}

.colour-cards .wrapper .colour-cards__card h3 {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: .8rem;
  font-size: 1.6rem;
  font-weight: 600;
}

@media screen and (width <= 960px) {
  .colour-cards .wrapper .colour-cards__card h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.colour-cards .wrapper .colour-cards__card p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 1.726rem;
}

.colour-cards .wrapper .colour-cards__card a {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  align-items: center;
  font-size: .8rem;
  font-weight: 600;
  text-decoration-line: none;
  display: flex;
}

.colour-cards .wrapper .colour-cards__card a img {
  margin-left: .25rem;
}

.colour-cards .wrapper .colour-cards__card-text {
  padding-bottom: 1rem;
}

@media (width >= 769px) {
  .colour-cards .wrapper .colour-cards__card-text {
    padding-bottom: 1.167rem;
  }
}

.colour-cards .wrapper .colour-cards__card .colour-cards__image {
  aspect-ratio: 399 / 241;
  object-fit: cover;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  width: 100%;
  height: auto;
}

.colour-cards .wrapper .colour-cards__card .colour-cards__content {
  padding: 1.2rem .889rem 0;
}

.page-cards {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  margin-bottom: 5rem;
  padding-bottom: 5rem;
}

.page-cards .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.page-cards .wrapper .page-cards__card {
  border-radius: 1rem;
  flex: 0 0 100%;
  margin-bottom: 1.33rem;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 1.6rem;
}

@media (width >= 769px) {
  .page-cards .wrapper .page-cards__card {
    flex: 0 0 calc(50% - 1.33rem);
    margin-left: .665rem;
    margin-right: .665rem;
  }
}

@media (width >= 961px) {
  .page-cards .wrapper .page-cards__card {
    flex: 0 0 calc(33.333% - 1.33rem);
  }
}

.page-cards .wrapper .page-cards__card h3 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: .8rem;
  font-size: 1.6rem;
  font-weight: 600;
}

@media screen and (width <= 960px) {
  .page-cards .wrapper .page-cards__card h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.page-cards .wrapper .page-cards__card p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 1.726rem;
}

.page-cards .wrapper .page-cards__card a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  align-items: center;
  font-size: .8rem;
  font-weight: 600;
  text-decoration-line: none;
  display: flex;
}

.page-cards .wrapper .page-cards__card a img {
  margin-left: .25rem;
}

.page-cards .wrapper .page-cards__card-text {
  padding-bottom: 1rem;
}

@media (width >= 769px) {
  .page-cards .wrapper .page-cards__card-text {
    padding-bottom: 1.167rem;
  }
}

.page-cards .wrapper .page-cards__card .page-cards__image {
  aspect-ratio: 399 / 241;
  object-fit: cover;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  width: 100%;
  height: auto;
}

.page-cards .wrapper .page-cards__card .page-cards__content {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.story-cards .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  display: flex;
}

@media (width >= 961px) {
  .story-cards .wrapper {
    margin: 0 auto;
  }
}

.story-cards .wrapper .story-cards__card {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex: 0 0 100%;
  margin: 0 0 2rem;
  overflow: hidden;
}

@media (width >= 769px) {
  .story-cards .wrapper .story-cards__card {
    flex: 0 0 calc(50% - 1rem);
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 961px) {
  .story-cards .wrapper .story-cards__card {
    flex: 0 0 calc(25% - 1rem);
  }
}

.story-cards .wrapper .story-cards__card a {
  text-decoration-line: none;
}

.story-cards .wrapper .story-cards__card img {
  aspect-ratio: 295 / 218;
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: auto;
  display: block;
}

.story-cards .wrapper .story-cards__card .story-cards__card__text {
  padding: 1rem;
}

@media (width >= 769px) {
  .story-cards .wrapper .story-cards__card .story-cards__card__text {
    padding: .889rem 0 0;
  }
}

.story-cards .wrapper .story-cards__card .story-cards__card__text h4 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: .25rem;
  padding-bottom: .25rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.778rem;
}

.story-cards .wrapper .story-cards__card .story-cards__card__text p {
  font-size: .8rem;
}

.story-cards + .donation-banner {
  margin-top: 3rem;
}

@media (width >= 769px) {
  .story-cards + .donation-banner {
    margin-top: 5rem;
  }
}

.downloads {
  padding-bottom: 5rem;
}

.downloads--three-col .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.downloads--three-col .downloads__card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex: 0 0 100%;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-bottom: 1.5rem;
}

@media (width >= 769px) {
  .downloads--three-col .downloads__card {
    flex: 0 0 calc(50% - 1rem);
  }
}

@media (width >= 961px) {
  .downloads--three-col .downloads__card {
    flex: 0 0 calc(33.333% - 1rem);
  }
}

.downloads--three-col .downloads__card--no-img {
  padding: 0;
}

.downloads--three-col .downloads__card__content {
  flex-direction: column;
  display: flex;
}

.downloads--three-col .downloads__card__image img {
  width: 100%;
  height: auto;
  margin-bottom: .5rem;
  display: block;
}

.downloads--three-col .downloads__card__text {
  flex: 0 0 75%;
  margin-left: 1rem;
  padding: .75rem .5rem 0 0;
}

.downloads .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.downloads__card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  padding-top: 0;
  padding-right: 0;
  overflow: hidden;
}

@media (width >= 769px) {
  .downloads__card {
    flex: 0 0 calc(50% - 1rem);
  }
}

@media (width >= 961px) {
  .downloads__card {
    flex: 0 0 100%;
  }
}

.downloads__card .downloads__card__content {
  display: block;
}

@media (width >= 961px) {
  .downloads__card .downloads__card__content {
    display: flex;
  }
}

.downloads__card .downloads__card__image {
  flex: 0 0 30%;
  align-items: center;
  padding: 0;
  display: flex;
}

@media (width >= 1025px) {
  .downloads__card .downloads__card__image {
    flex: 0 0 40%;
  }
}

.downloads__card .downloads__card__image img {
  width: 100%;
  height: auto;
  display: block;
}

@media (width >= 769px) {
  .downloads__card .downloads__card__image img {
    height: 15rem;
  }
}

@media (width >= 961px) {
  .downloads__card .downloads__card__image img {
    height: 100%;
  }
}

.downloads__card .downloads__card__text {
  margin-left: 1rem;
}

.downloads__card h3 {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: .25rem;
  font-size: 20px;
  font-weight: 600;
}

@media (width >= 1025px) {
  .downloads__card h3 {
    padding-bottom: .4rem;
  }
}

.downloads__card a {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  border-width: 0;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: 0;
  padding-right: 0;
  font-weight: 700;
  text-decoration-line: none;
  display: flex;
}

.downloads__card a img {
  margin-left: .25rem;
}

.downloads__card__content {
  display: flex;
}

.downloads__card__text {
  flex: 0 0 100%;
  margin: 0;
  padding: .75rem 1.5rem 2rem 0;
}

@media (width >= 769px) {
  .downloads__card__text {
    margin-left: .5rem;
    padding-top: 2rem;
    padding-left: 1.5rem;
  }
}

@media (width >= 961px) {
  .downloads__card__text {
    flex: 0 0 calc(70% - 1.5rem);
  }
}

@media (width >= 1025px) {
  .downloads__card__text {
    flex: 0 0 calc(60% - 1.5rem);
  }
}

.downloads__card__text p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  font-size: .9rem;
}

.downloads__card__info {
  padding-bottom: 0;
}

.downloads__card__info span {
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
  display: inline-block;
}

.downloads__card__actions a {
  margin-top: .5rem;
}

.downloads__card__actions span {
  font-size: .8rem;
}

.downloads__card--no-img {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 0;
  overflow: visible;
}

.downloads__card--no-img .downloads__card__content {
  justify-content: flex-end;
  height: 100%;
}

.downloads__card--no-img .downloads__card__text {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex: 0;
  margin: 0;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
}

.people-cards {
  margin-bottom: 5rem;
}

.people-cards .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.people-cards .wrapper .people-cards__card {
  flex: 0 0 100%;
  width: 3rem;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-bottom: 1.5rem;
}

@media (width >= 561px) {
  .people-cards .wrapper .people-cards__card {
    flex: 0 0 calc(50% - 1rem);
  }
}

@media (width >= 961px) {
  .people-cards .wrapper .people-cards__card {
    flex: 0 0 calc(25% - 1rem);
  }
}

.people-cards .wrapper .people-cards__card img {
  border-radius: .25rem;
  width: 100%;
  margin-bottom: 1rem;
}

.people-cards .wrapper .people-cards__card h3 {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
  margin-bottom: .25rem;
  padding-bottom: 0;
  font-size: 20px;
  line-height: 1.4rem;
}

.people-cards .wrapper .people-cards__card .job-title {
  --tw-text-opacity: 1;
  color: rgb(197 197 197 / var(--tw-text-opacity));
  padding-bottom: .25rem;
}

.people-cards .wrapper .people-cards__card p {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: .8rem;
  line-height: 1.2rem;
}

.detail-card {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  margin-bottom: 4rem;
  overflow: hidden;
}

.detail-card .detail-card__title {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  padding: 1.167rem 1.5rem;
}

.detail-card .detail-card__title h4 {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.25;
}

.detail-card .detail-card__details {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2rem 1.5rem;
  display: block;
}

@media (width >= 769px) {
  .detail-card .detail-card__details {
    display: flex;
  }
}

.detail-card .detail-card__details li, .detail-card .detail-card__details p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: .9rem;
  line-height: 1.6rem;
}

.detail-card .detail-card__column {
  flex: 0 0 calc(50% - .5rem);
  margin: 0;
}

.detail-card .detail-card__column:nth-child(odd) {
  margin: 0 1rem 0 0;
}

.detail-card .detail-card__column > :last-child:not(ul):not(ol) {
  margin-bottom: 1rem;
}

.events-cards__heading {
  text-align: center;
  width: 40%;
  margin: 0 auto 3rem;
}

.events-cards {
  margin: 0;
  padding-bottom: 3rem;
  display: block;
}

@media (width >= 769px) {
  .events-cards {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -.665rem;
    padding-bottom: 4.5rem;
    display: flex;
  }
}

.events-cards__card {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex: 0 0 100%;
  margin: 0 0 4rem;
}

@media (width >= 769px) {
  .events-cards__card {
    flex: 0 0 calc(50% - 1rem);
    margin-bottom: 2rem;
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 961px) {
  .events-cards__card {
    flex: 0 0 calc(33.333% - 1rem);
  }
}

.events-cards__card-text {
  padding: 1rem;
}

@media (width >= 769px) {
  .events-cards__card-text {
    padding: .889rem 1.5rem 0;
  }
}

.events-cards__card-text .event-cards__event-details {
  padding-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

@media (width >= 769px) {
  .events-cards__card-text .event-cards__event-details {
    padding-bottom: .25rem;
  }
}

.events-cards__card-text .event-cards__event-details li {
  align-items: center;
  margin-bottom: .889rem;
  font-size: .9rem;
  display: flex;
}

.events-cards__card-text .event-cards__event-details li img {
  border-width: 0;
  flex: 0 0 calc(5% - .5rem);
  width: 1rem;
  height: auto;
  margin-right: 1rem;
}

.events-cards__card-text .event-cards__event-details li span {
  flex: 0 0 calc(95% - .5rem);
  padding-top: 0;
  padding-right: 1rem;
}

.events-cards__card .events-cards__card__image img {
  aspect-ratio: 396 / 218;
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: auto;
}

.events-cards__card h4 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: .25rem;
  font-size: 20px;
  line-height: 1.4rem;
}

.events-cards__card p {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 1.2rem;
  font-size: .9rem;
}

.events-cards__card > .btn {
  min-width: 9.6rem;
  margin: 0 1rem;
  padding: .305rem 1.5rem;
}

@media (width >= 769px) {
  .events-cards__card > .btn {
    margin: 0 1.5rem 1.556rem;
  }
}

.events-index .events-cards {
  padding-bottom: .25rem;
}

.event-info {
  padding-bottom: 2.5rem;
  display: block;
}

@media (width >= 769px) {
  .event-info {
    padding-bottom: 5rem;
  }
}

.event-info__container {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 769px) {
  .event-info__container {
    padding: 2rem;
  }
}

.event-info__container ul {
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
}

.event-info__container ul li {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  flex: 0 0 100%;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: .5rem;
  display: flex;
}

@media (width >= 769px) {
  .event-info__container ul li {
    flex: 0 0 50%;
    margin-bottom: 0;
  }
}

.event-info__container ul li:last-child {
  margin-bottom: 0;
  padding: 0;
}

.event-info__container ul li img {
  width: 1.25rem;
  height: auto;
  margin-right: 1rem;
}

.event-info__container > .btn {
  display: block;
}

.text-image {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(249 249 249 / var(--tw-border-opacity));
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

@media (width >= 769px) {
  .text-image {
    margin-bottom: 3.6rem;
    padding-bottom: 3.6rem;
  }
}

.text-image:last-of-type {
  border-bottom-width: 0;
  margin-bottom: 5rem;
}

.text-image:not(:first-child) {
  margin-top: 2rem;
}

@media (width >= 769px) {
  .text-image:not(:first-child) {
    margin-top: 3.6rem;
  }
}

.text-image + .quote-banner {
  margin-top: -5rem;
}

.text-image + .colour-cards {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(19 20 22 / var(--tw-border-opacity));
  margin-top: -.05rem;
  padding-top: 2.5rem;
}

.text-image__block {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  flex-flow: column wrap;
  display: flex;
  overflow: hidden;
}

@media (width >= 961px) {
  .text-image__block {
    flex-direction: row;
  }
}

.text-image__block.image-left .text-image__block__image {
  order: 1;
}

@media (width >= 769px) {
  .text-image__block.image-left .text-image__block__image {
    order: 1;
  }
}

.text-image__block.image-left .text-image__block__text {
  order: 2;
  padding-right: 1rem;
}

@media (width >= 769px) {
  .text-image__block.image-left .text-image__block__text {
    order: 2;
    padding-left: 7rem;
    padding-right: 0;
  }
}

.text-image__block .text-image__block__text {
  align-items: center;
  display: flex;
}

.text-image__block__text {
  flex: 0 0 100%;
  order: 2;
  padding: 1rem;
}

@media (width >= 769px) {
  .text-image__block__text {
    flex: 0 0 60%;
    order: 1;
    padding-top: 1rem;
    padding-right: 7rem;
  }
}

.text-image__block__text h3 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.text-image__block__text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
}

.text-image__block__image {
  flex: 0 0 100%;
  order: 1;
  width: 100%;
  min-height: 23.333rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

@media (width >= 769px) {
  .text-image__block__image {
    flex: 0 0 40%;
    order: 2;
  }
}

.text-image__block__image img {
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.secondary-text-image__block {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex-flow: column wrap;
  margin-bottom: 5rem;
  display: flex;
  overflow: hidden;
}

@media (width >= 769px) {
  .secondary-text-image__block {
    flex-direction: row;
  }
}

.secondary-text-image__block.image-left .secondary-text-image__block__image {
  order: 1;
}

.secondary-text-image__block.image-left .secondary-text-image__block__text {
  order: 2;
  padding-right: 1rem;
}

@media (width >= 769px) {
  .secondary-text-image__block.image-left .secondary-text-image__block__text {
    order: 2;
    padding-left: 3.313rem;
    padding-right: 2.063rem;
  }
}

.secondary-text-image__block .secondary-text-image__block__text {
  align-items: center;
  display: flex;
}

.secondary-text-image__block__text {
  flex: 0 0 100%;
  order: 2;
  padding: 1rem;
}

@media (width >= 769px) {
  .secondary-text-image__block__text {
    flex: 0 0 50%;
    order: 1;
    padding: 1rem 3.313rem 1rem 2.063rem;
  }
}

.secondary-text-image__block__text h3 {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 600;
}

.secondary-text-image__block__text p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: 1.75rem;
  font-size: .9rem;
}

.secondary-text-image__block__text .btn {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  background-color: #0000;
  border-width: 0;
  align-items: center;
  padding: 0;
  font-size: .8rem;
  display: flex;
}

.secondary-text-image__block__text .btn:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.secondary-text-image__block__text .btn img {
  width: 1.667rem;
  margin-left: .25rem;
  display: block;
}

@media (width >= 769px) {
  .secondary-text-image__block__text .btn img {
    margin-left: .25rem;
  }
}

.secondary-text-image__block__image {
  flex: 0 0 100%;
  order: 1;
  width: 100%;
  min-height: 23.333rem;
  position: relative;
}

@media (width >= 769px) {
  .secondary-text-image__block__image {
    flex: 0 0 50%;
  }
}

.secondary-text-image__block__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pagination {
  padding-bottom: 2.25rem;
  display: block;
}

.pagination__items {
  text-align: center;
}

.pagination__pages a:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 232 77 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.pagination__pages a, .pagination__pages span {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  width: 2rem;
  margin: 0 .1rem;
  padding-top: .3rem;
  padding-bottom: .3rem;
  font-size: .8rem;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.pagination__pages a img, .pagination__pages span img {
  object-fit: contain;
  margin-left: .111rem;
  display: block;
}

.pagination__pages a[rel="prev"], .pagination__pages span[rel="prev"] {
  border-width: 0;
}

.pagination__pages a[rel="prev"] img, .pagination__pages span[rel="prev"] img {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left: 0;
  margin-right: .111rem;
}

.pagination__pages a[rel="prev"]:hover, .pagination__pages span[rel="prev"]:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: #0000;
  border-width: 0;
}

.pagination__pages a[rel="next"], .pagination__pages span[rel="next"] {
  border-width: 0;
  border-color: #0000;
}

.pagination__pages a[rel="next"]:hover, .pagination__pages span[rel="next"]:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: #0000;
  border-width: 0;
}

.pagination__pages .dots {
  border-width: 0;
  padding: .167rem 0;
}

.pagination__pages .active {
  --tw-border-opacity: 1;
  border-color: rgb(248 232 77 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.pagination__pages .active span {
  font-weight: 600;
}

.form-designer {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 1rem;
  flex-wrap: wrap;
  max-width: 36rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.form-designer .form-designer__intro {
  flex-wrap: wrap;
  display: flex;
}

.form-designer .form-designer__intro h3 {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  padding-bottom: 0;
}

.form-designer .form-designer__intro.form-designer__intro--wide-text {
  display: block;
}

.form-designer .form-designer__intro.form-designer__intro--wide-text .form-designer__intro__text {
  padding: 3.889rem 3.889rem 0;
}

.form-designer .form-designer__intro .form-designer__intro__text {
  flex: 0 0 50%;
  padding: 2rem 2rem 0;
}

@media (width >= 961px) {
  .form-designer .form-designer__intro .form-designer__intro__text {
    padding-top: 3.889rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.form-designer .form-designer__intro .form-designer__intro__text p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
}

.form-designer .form-designer__intro .form-designer__intro__image {
  flex: 0 0 50%;
  order: 1;
  width: 100%;
  padding-left: 2rem;
  padding-right: 1.6rem;
}

@media (width >= 961px) {
  .form-designer .form-designer__intro .form-designer__intro__image {
    order: 2;
    padding-left: 0;
  }
}

.form-designer .form-designer__intro .form-designer__intro__image img {
  border-radius: 1rem;
  width: 100%;
  margin-top: 2rem;
}

.form-designer .form-designer__intro .form-designer__intro__text, .form-designer .form-designer__intro .form-designer__intro__image {
  flex: 0 0 100%;
}

@media (width >= 961px) {
  .form-designer .form-designer__intro .form-designer__intro__text, .form-designer .form-designer__intro .form-designer__intro__image {
    flex: 0 0 50%;
  }
}

.form-designer .form-designer__form {
  padding: 2rem;
}

@media (width >= 961px) {
  .form-designer .form-designer__form {
    padding: 2rem 1.55rem;
  }
}

.form-designer .form-designer__form form fieldset {
  padding: 0 0 2rem;
}

.form-designer .form-designer__form form fieldset:last-child {
  padding-bottom: 0;
}

.form-designer .form-designer__form form fieldset .full-name {
  padding-bottom: 1rem;
  display: block;
}

@media (width >= 769px) {
  .form-designer .form-designer__form form fieldset .full-name {
    justify-content: space-between;
    display: flex;
  }
}

.form-designer .form-designer__form form fieldset .full-name .field {
  flex: 0 0 calc(50% - .5rem);
}

.form-designer .form-designer__form form .required {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.form-designer .form-designer__form form label {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
}

.form-designer .form-designer__form form input, .form-designer .form-designer__form form select, .form-designer .form-designer__form form textarea {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-color: rgb(196 196 205 / var(--tw-border-opacity));
  border-radius: .25rem;
}

.form-designer .form-designer__form form .field--checkboxinput label, .form-designer .form-designer__form form .field--radios label {
  font-size: 0;
}

.form-designer .form-designer__form form .checkbox {
  display: flex;
}

.form-designer .form-designer__form form .field--radios li {
  padding: .5rem;
}

.form-designer .form-designer__form form .field__select {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(196 196 205 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.form-designer .form-designer__form form .field__select select {
  border-width: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-designer .form-designer__form form input, .form-designer .form-designer__form form select, .form-designer .form-designer__form form textarea {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
}

.form-designer .form-designer__form form input::placeholder, .form-designer .form-designer__form form select::placeholder, .form-designer .form-designer__form form textarea::placeholder {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.form-designer .form-designer__form form .btn {
  text-align: center;
  min-width: 12.8rem;
  margin: 0;
  display: block;
}

.form-designer .form-designer__form form .field--checkboxinput {
  display: flex;
}

.form-designer .form-designer__form form .field--checkboxinput p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
  font-weight: 700;
}

.form-designer .form-designer__form form .help-text {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  font-size: .8rem;
}

.two-col-text {
  margin-bottom: 5rem;
  display: block;
}

@media (width >= 769px) {
  .two-col-text {
    display: flex;
  }
}

.two-col-text li, .two-col-text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: .9rem;
  line-height: 1.6rem;
}

.two-col-text .two-col-text__column {
  flex: 0 0 calc(50% - 1rem);
  margin: 0;
}

.two-col-text .two-col-text__column:nth-child(odd) {
  margin: 0 2rem 0 0;
}

.two-col-text .two-col-text__column > :last-child:not(ul):not(ol) {
  margin-bottom: 1rem;
}

.video-block {
  border-radius: .25rem;
  padding-bottom: 2rem;
}

@media (width >= 961px) {
  .video-block {
    padding-bottom: 2rem;
  }
}

.video-block__container {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
}

.video-block__image {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  width: auto;
  height: 500px;
  display: block;
  position: relative;
}

.video-block__image img {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (width >= 961px) {
  .video-block__image img {
    width: 5rem;
  }
}

.video-block__play {
  width: 3rem;
}

.quote {
  --tw-bg-opacity: 1;
  background-color: rgb(19 20 22 / var(--tw-bg-opacity));
  margin-bottom: 3rem;
  padding-top: 0;
  padding-bottom: 0;
}

.quote .quote__image-black {
  display: none;
}

.quote .quote__image-yellow {
  display: block;
}

.quote .wrapper {
  display: flex;
}

.quote .wrapper img {
  height: 2rem;
  margin-right: 1.5rem;
}

.quote .wrapper p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  padding-bottom: .5rem;
}

.quote .wrapper .quote__quote {
  max-width: 29rem;
}

.quote .wrapper span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .8rem;
  font-weight: 600;
}

.quote--yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.quote--yellow .quote__image-black {
  display: block;
}

.quote--yellow .quote__image-yellow {
  display: none;
}

.quote--yellow .quote__quote p, .quote--yellow .quote__quote span {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.quote-banner {
  --tw-bg-opacity: 1;
  background-color: rgb(248 232 77 / var(--tw-bg-opacity));
  flex-wrap: wrap;
  margin-bottom: 3rem;
  display: flex;
}

.quote-banner .quote-banner__text {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
  flex: 0 0 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1.25rem;
  display: flex;
}

@media (width >= 769px) {
  .quote-banner .quote-banner__text {
    flex: 0 0 50%;
    padding: 7.2rem 1.25rem 7.2rem 3rem;
  }
}

.quote-banner .quote-banner__text img {
  height: 2rem;
  margin-right: 1.5rem;
}

.quote-banner .quote-banner__text .quote-banner__text__quote {
  max-width: 26.9rem;
}

.quote-banner .quote-banner__text .quote-banner__text__quote p {
  margin-bottom: 0;
  padding-bottom: .5rem;
  font-size: 20px;
}

@media (width >= 769px) {
  .quote-banner .quote-banner__text .quote-banner__text__quote p {
    font-size: 1.2rem;
  }
}

.quote-banner .quote-banner__text .quote-banner__text__quote span {
  font-size: .8rem;
  font-weight: 600;
}

.quote-banner .quote-banner__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media (width >= 769px) {
  .quote-banner .quote-banner__image {
    flex: 0 0 50%;
  }
}

.quote-banner .quote-banner__image {
  -webkit-clip-path: ellipse(100% 105% at 100%);
  clip-path: ellipse(100% 105% at 100%);
}

.quote-banner + .site-footer {
  margin-top: -3rem;
}

.donation-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 4rem;
  padding: 2.5rem 0;
}

@media (width >= 769px) {
  .donation-banner {
    padding-top: 6.85rem;
    padding-bottom: 6.85rem;
  }
}

.donation-banner .wrapper {
  align-items: center;
  display: flex;
}

.donation-banner .donation-banner__text {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  padding: 2rem 1rem;
  display: flex;
}

@media (width >= 961px) {
  .donation-banner .donation-banner__text {
    max-width: 33.6rem;
    padding: 3.25rem 4.25rem;
  }
}

.donation-banner .donation-banner__text h3 {
  max-width: 22.3rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6rem;
  font-weight: 600;
}

.donation-banner .donation-banner__text h3, .donation-banner .donation-banner__text p {
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.donation-banner .donation-banner__text__btn {
  display: block;
}

@media (width >= 561px) {
  .donation-banner .donation-banner__text__btn {
    gap: .7rem;
    width: 100%;
    display: flex;
  }
}

.donation-banner .donation-banner__text__btn .btn {
  margin-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 561px) {
  .donation-banner .donation-banner__text__btn .btn {
    flex: 0 0 calc(50% - .4rem);
  }
}

.donation-banner .donation-banner__text__btn .btn:hover {
  --tw-border-opacity: 1;
  border-color: rgb(19 20 22 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(19 20 22 / var(--tw-text-opacity));
}

.logo-block {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(249 249 249 / var(--tw-border-opacity));
}

.logo-block .logo-block__heading {
  text-align: center;
  margin-bottom: 4rem;
  margin-left: 0;
  margin-right: 0;
}

.logo-block .logo-block__heading h3 {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(184 184 185 / var(--tw-text-opacity));
  margin-bottom: 2.15rem;
  padding-bottom: 0;
  font-size: .8rem;
  font-weight: 500;
}

.logo-block .logo-block__images {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3.3rem;
  display: flex;
}

.logo-block .logo-block__images img {
  margin-left: 1rem;
  margin-right: 1rem;
}

.logo-block .logo-block__btn {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}

.gallery {
  margin-bottom: 5rem;
  display: block;
}

.gallery__block, .gallery__block__main {
  width: 100%;
}

.gallery__block__main img {
  aspect-ratio: 820 / 447;
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  padding-bottom: 0;
}

.gallery__block__thumbs {
  cursor: pointer;
  border-radius: .25rem;
  flex-wrap: wrap;
  display: flex;
}

.gallery__block__thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  flex: 0 0 100%;
  width: 10rem;
  height: 7rem;
  margin: 1rem .35rem 0;
}

@media (width >= 769px) {
  .gallery__block__thumb {
    flex: 0 0 calc(50% - .7rem);
  }
}

@media (width >= 961px) {
  .gallery__block__thumb {
    flex: 0 0 calc(25% - .7rem);
  }
}

.title-block {
  text-align: center;
  width: 100%;
  margin: 0 auto 3rem;
}

.title-block .wrapper {
  max-width: 37.75rem;
}

.title-block .title-block__sub-heading {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(184 184 185 / var(--tw-text-opacity));
  margin-bottom: 1.35rem;
  padding-bottom: 0;
  font-size: .8rem;
}

.title-block h3 {
  --tw-text-opacity: 1;
  color: rgb(248 232 77 / var(--tw-text-opacity));
  padding: 0 2rem;
  font-size: 2.4rem;
  font-weight: 600;
}

.title-block .title-block__btn {
  text-align: center;
  margin: 2.35rem 0 5rem;
}

.title-block + .colour-cards {
  margin-top: 4.2rem;
}

.share-this-page {
  margin-bottom: 0;
}

@media (width >= 769px) {
  .share-this-page {
    margin-bottom: 0;
  }
}

.share-this-page .wrapper {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  padding-top: 1.5rem;
  padding-left: 1rem;
  display: flex;
}

@media (width >= 769px) {
  .share-this-page .wrapper {
    padding-left: 0;
  }
}

.share-this-page .wrapper p {
  font-size: .9rem;
}

.share-this-page .wrapper .share-this-page__social ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.share-this-page .wrapper .share-this-page__social ul li {
  margin-right: .5rem;
  list-style-type: none;
}

.share-this-page .wrapper .share-this-page__social ul li:first-child {
  margin-left: 0;
  padding-left: 0;
}

/*# sourceMappingURL=main.css.map */
